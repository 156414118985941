import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const Notifiche = () => {
  return (
    <>
      <Box p={3} pt={15}>
        <Typography variant="h4">Notifiche</Typography>
        <p>Menu dropdown dall'icona:</p>
        <ul>
          <li>Promemoria per lezioni live prenotate</li>
          <li>
            Promemoria per lezioni che di solito fai ma a cui non ti sei ancora
            prenotato
          </li>
          <li>Nuovo video di un insegnante di cui hai attivato le notifiche</li>
          <li>Risposte ai tuoi commenti</li>
          <li>Persone che ti hanno iniziato a seguire</li>
          <li>Notifiche relative al tuo account</li>
        </ul>
        <p>Le notifiche sono interamente personalizzabili</p>
      </Box>
    </>
  );
};

export default Notifiche;
