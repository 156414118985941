import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import NotificationsIcon from "@material-ui/icons/Notifications";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";

const Notifications = () => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = (path: string) => {
    history.push(path);
    handleClose();
  };

  return (
    <>
      <IconButton
        size="small"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <NotificationsIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigateTo("/notifications")}>
          Contenuto notifiche
        </MenuItem>
        <MenuItem onClick={handleClose}>ex: Nuovo Video da Anna!</MenuItem>
        <MenuItem onClick={handleClose}>
          ex: A Piera piace il tuo post!
        </MenuItem>
        <MenuItem onClick={handleClose}>
          ex: Il tuo abbonamento sta per scadere!
        </MenuItem>
      </Menu>
    </>
  );
};

export default Notifications;
