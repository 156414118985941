import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const LiveVideo = () => {
  return (
    <>
      <Box p={3} pt={15}>
        <Typography variant="h4">LIVE - Video Singolo</Typography>
        <p>Contenuti:</p>
        <ul>
          <li>Video Call in stile zoom</li>
          <li>Video e Audio sia per utenti che per insegnanti</li>
          <li>Video insegnante in evidenza</li>
          <li>
            Pulsante "muta tutti" per mutare gli utenti sia con lucchetto sia
            con possibilità di smutarsi
          </li>
          <li>
            Possibilità di condivisione dello schermo sia da parte
            dell'insegnante sia da parte dell'admin
          </li>
          <li>
            Possibilità di registrare la pratica e farla rivedere più tardi a
            chi era prenotato e non ha partecipato
          </li>
          <li>Chat</li>
          <li>Diverse opzioni di vista ''galleria'' o ''insegnante''</li>
          <li>
            Opzione ''fare due chiacchere'': possibilità prima dell'inizio della
            lezione di comunicare a coppie o gruppi di tre
          </li>
          <li>Nessuna limitazione di persones</li>
          <li>
            Video in qualità ridotta (priorità alla fruibilità anche con
            connessione scarsa)
          </li>
        </ul>
      </Box>
    </>
  );
};

export default LiveVideo;
