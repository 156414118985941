import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const ProfiloInsegnante = () => {
  return (
    <>
      <Box p={3} pt={15}>
        <Typography variant="h4">Profilo Insegnante</Typography>
        <p>
          La pagina deve essere personalizzabile dagli insegnanti a livello di:
        </p>
        <ul>
          <li>Immagini da inserire (profilo, copertina)</li>
          <li>Colore dominante</li>
          <li>Bio e informazioni personali</li>
          <li>Video di presentazione</li>
        </ul>
        <p>Contenuti:</p>
        <ul>
          <li>Elenco dei followers</li>
          <li>Orario delle Live</li>
          <li>Tasto per accesso diretto alla prossima live</li>
          <li>Classi registrate di MYpractice</li>
          <li>
            Possibilità di essere seguiti (come instagram) e di prenotarsi alla
            classe
          </li>
          <li>Possibilità di fare classi private su richiesta</li>
          <li>Possibilità di scrivere nuovi post</li>
          <li>Post automatico per live e per nuovi video MYpractice</li>
        </ul>
      </Box>
    </>
  );
};

export default ProfiloInsegnante;
