import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const MYextra = () => {
  const history = useHistory();
  const handleClick = (path: string) => history.push(path);
  return (
    <>
      <Box p={3} pt={15}>
        <Typography variant="h4">MYextra</Typography>
        <p>Video in pay-per-view con pagamento per singolo video</p>
        <ul>
          <li>Filtri</li>
          <li>Workshop tematici</li>
          <li>Corsi</li>
        </ul>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleClick("/myextra/video")}
        >
          MYextra Single Video
        </Button>
      </Box>
    </>
  );
};

export default MYextra;
