import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const Live = () => {
  const history = useHistory();
  const handleClick = (path: string) => history.push(path);
  return (
    <>
      <Box p={3} pt={15}>
        <Typography variant="h4">LIVE</Typography>
        <p>
          Ogni live deve essere prenotabile da questa pagina e viene pagata
          singolarmente tramite una riserva di fondi legata all'account chiamata
          portafogli.
        </p>
        <p>Contenuti della pagina:</p>
        <ul>
          <li>
            Calendario delle pratiche con filtri per
            orario/giorno/insegnante/genere
          </li>
          <li>Classi imminenti messe in evidenza</li>
        </ul>
        <p>Gli utenti vedono anche:</p>
        <ul>
          <li>Prossima live a cui sono prenotati</li>
          <li>Pulsante per accedere alla prossima live a cui sono prenotati</li>
        </ul>
        <p>Gli insegnanti possono:</p>
        <ul>
          <li>Programmare una nuova LIVE</li>
          <li>Avviare una LIVE programmata</li>
          <li>Vedere gli iscritti alle proprie LIVE programmate</li>
        </ul>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleClick("/live/video")}
        >
          Live Room
        </Button>
      </Box>
    </>
  );
};

export default Live;
