import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const MYpractice = () => {
  const history = useHistory();
  const handleClick = (path: string) => history.push(path);
  return (
    <>
      <Box p={3} pt={15}>
        <Typography variant="h4">MYpractice</Typography>
        <p>
          Video registrati onDemand con iscrizione ad abbonamento(mensile o
          annuale)
        </p>
        <ul>
          <li>Video consigliati per te</li>
          <li>La mia lista (video salvati)</li>
          <li>filtri</li>
          <li>Playlist (raccolte di video per tema)</li>
          <li>Corsi guidati con indicazioni su come procedere</li>
        </ul>
        <p>Personalizzazione:</p>
        <ul>
          <li>I video già visti sono flaggati</li>
        </ul>
        <p>Due tipi di organizzazione:</p>
        <ul>
          <li>Corsi guidati</li>
          <li>Video singoli</li>
        </ul>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleClick("/mypractice/video")}
        >
          MYpractice Single Video
        </Button>
      </Box>
    </>
  );
};

export default MYpractice;
