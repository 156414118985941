import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";
import Notifications from "./Notifications";
import Profile from "./Profile";

const TopNav = () => {

  return (
    <AppBar color="default" position="fixed">
      <Paper style={{ display: "flex", justifyContent: "center" }}>
        <Box p={2} style={{ flexGrow: 1 }} maxWidth={600}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs>
              <TextField
                id="input-with-icon-textfield"
                label="Cerca"
                style={{ width: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs="auto">
              <Notifications />
            </Grid>
            <Grid item xs="auto">
              <Profile />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </AppBar>
  );
};

export default TopNav;
