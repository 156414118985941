import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const MenuProfilo = () => {
  const history = useHistory();
  const handleClick = (path: string) => history.push(path);
  return (
    <>
      <Box p={3} pt={15}>
        <Typography variant="h4">Menu Profilo</Typography>
        <p>Menu dropdown dall'icona:</p>
        <ul>
          <li>Link al profilo pubblico</li>
          <li>Portafoglio per pagare le classi LIVE</li>
          <li>Gestione Abbonamento MYpractice</li>
          <li>Agenda (lezioni LIVE prenotate)</li>
          <li>Storico delle attività (video guardati)</li>
          <li>Impostazioni dati personali</li>
        </ul>
        <Button
          style={{ marginRight: 15 }}
          variant="outlined"
          color="primary"
          onClick={() => handleClick("/profile/user")}
        >
          Profilo Utente
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleClick("/profile/teacher")}
        >
          Profilo Insegnante
        </Button>
      </Box>
    </>
  );
};

export default MenuProfilo;
