import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const ProfiloUtente = () => {
  return (
    <>
      <Box p={3} pt={15}>
        <Typography variant="h4">Profilo Utente</Typography>
        <p>La pagina deve essere personalizzabile dagli utenti a livello di:</p>
        <ul>
          <li>Immagini da inserire (profilo, copertina)</li>
          <li>informazioni personali</li>
        </ul>
        <p>Contenuti:</p>
        <ul>
          <li>Elenco degli amici (follow sempre reciproco)</li>
          <li>Post</li>
        </ul>
        <p>Possibilità di condividere:</p>
        <ul>
          <li>Lezioni appena fatte</li>
          <li>Prenotazioni a live future</li>
          <li>Post di altre persone</li>
        </ul>
        <p>Funzionalità extra:</p>
        <ul>
          <li>
            Possibilità di avviare una pratica registrata in video chat con gli
            amici (tipo Netflix Party)
          </li>
        </ul>
      </Box>
    </>
  );
};

export default ProfiloUtente;
