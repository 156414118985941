import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const MYpracticeVideo = () => {
  return (
    <>
      <Box p={3} pt={15}>
        <Typography variant="h6">MYpractice - video</Typography>
        <p>Contenuti</p>
        <ul>
          <li>Video</li>
          <li>Reazioni al video (tipo like)</li>
          <li>Descrizione</li>
          <li>Metadati del video</li>
          <li>Feedback degli altri utenti</li>
          <li>Commenti</li>
          <li>Video da fare dopo (meditazione dopo yoga)</li>
        </ul>
        <p>Interazioni</p>
        <ul>
          <li>Commentare</li>
          <li>Lasciare reazioni al video</li>
          <li>Rispondere al questionario per feedback</li>
          <li>Aggiungere ai preferiti</li>
          <li>Condividi sul tuo profilo</li>
          <li>Consiglia a un amico</li>
          <li>Vedere amici che l'hanno già fatto</li>
        </ul>
      </Box>
    </>
  );
};

export default MYpracticeVideo;
