import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";

const Feed = () => {
  const history = useHistory();
  const handleClick = (path: string) => history.push(path);

  return (
    <>
      <Box p={3} pt={15}>
        <Typography variant="h4">Feed</Typography>
        <p>
          MYmondo sarà organizzato come un social network, quindi la pagina
          principale sarà un feed dinamico.
        </p>
        <p>Nel feed compaiono:</p>
        <ul>
          <li>Nuovi video registrati</li>
          <li>
            Post degli <Link href="/profile/teacher">insegnanti</Link>
          </li>
          <li>Nuovi workshop e corsi</li>
          <li>
            Post degli <Link href="/profile/user">utenti</Link> che segui
          </li>
          <li>Post di MYmondo</li>
          <li>Live imminenti</li>
        </ul>
        <p>
          I contenuti vengono proposti in base agli utenti/insegnanti/stili che
          segui
        </p>
        <p>
          Oltre a questo vengono anche proposti occasionalmente contenuti mai
          provati
        </p>
      </Box>
    </>
  );
};

export default Feed;
