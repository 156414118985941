import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HomeIcon from "@material-ui/icons/Home";
import VideocamIcon from "@material-ui/icons/Videocam";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

export default function LabelBottomNavigation() {
  const [value, setValue] = useState("");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const history = useHistory();
  const handleClick = (path: string) => {
    history.push(path);
  };

  //   useEffect(() => {
  //     history.push("/" + value);
  //   }, [value]);

  return (
    <BottomNavigation value={value} onChange={handleChange}>
      <BottomNavigationAction
        label="Home"
        value=""
        onClick={() => handleClick("/")}
        icon={<HomeIcon />}
      />
      <BottomNavigationAction
        label="MYpractice"
        value="mypractice"
        onClick={() => handleClick("/mypractice")}
        icon={<FavoriteIcon />}
      />
      <BottomNavigationAction
        label="Live"
        value="live"
        onClick={() => handleClick("/live")}
        icon={<VideocamIcon />}
      />
      <BottomNavigationAction
        label="MYextra"
        value="myextra"
        onClick={() => handleClick("/myextra")}
        icon={<AttachMoneyIcon />}
      />
    </BottomNavigation>
  );
}
