import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";

import TopNav from "./components/TopNav";
import LabelBottomNavigation from "./components/LabelBottomNavigation";

import Feed from "./pages/Feed";
import MenuProfilo from "./pages/MenuProfilo";
import Notifiche from "./pages/Notifiche";
import MYpractice from "./pages/MYpractice";
import MYpracticeVideo from "./pages/MYpracticeVideo";
import Live from "./pages/Live";
import LiveVideo from "./pages/LiveVideo";
import MYextra from "./pages/MYextra";
import ProfiloInsegnante from "./pages/ProfiloInsegnante";
import ProfiloUtente from "./pages/ProfiloUtente";
import MYextraVideo from "./pages/MYextraVideo";

const App = () => {
  const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#E7C4C1",
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: "#e96c63",
        // dark: will be calculated from palette.secondary.main,
      },
      background: {
        paper: "#222B36",
        default: "#171C24",
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
  });
  console.log(theme);
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <TopNav />
        <Box
          maxWidth={600}
          style={{
            margin: "auto",
            border: "1px solid #7e7e7e",
            minHeight: "100vh",
          }}
        >
          <Switch>
            <Route path="/" exact>
              <Feed />
            </Route>
            <Route path="/mypractice" exact>
              <MYpractice />
            </Route>
            <Route path="/mypractice/video" exact>
              <MYpracticeVideo />
            </Route>
            <Route path="/live" exact>
              <Live />
            </Route>
            <Route path="/live/video" exact>
              <LiveVideo />
            </Route>
            <Route path="/myextra" exact>
              <MYextra />
            </Route>
            <Route path="/myextra/video">
              <MYextraVideo />
            </Route>
            <Route path="/notifications" exact>
              <Notifiche />
            </Route>
            <Route path="/profile" exact>
              <MenuProfilo />
            </Route>
            <Route path="/profile/user" exact>
              <ProfiloUtente />
            </Route>
            <Route path="/profile/teacher" exact>
              <ProfiloInsegnante />
            </Route>
          </Switch>
        </Box>
        <AppBar
          color="default"
          position="fixed"
          style={{ bottom: 0, top: "initial" }}
        >
          <LabelBottomNavigation />
        </AppBar>
      </ThemeProvider>
    </Router>
  );
};

export default App;
